<template>
  <div class="agenda">
    <Navbar />
    <Welcome
      :deskripsi="layout.deskripsi"
      :nama_sekolah="layout.nama_sekolah"
    />

    <div class="container">
      <div class="row py-3">
        <div class="col-md-7 col-lg-8 mb-4">
          <h2 class="header"><i class="bi-newspaper"></i> Semua Agenda</h2>
          <div class="row mt-4">
            <div
              class="col-lg-6 mb-3"
              v-for="agenda in agenda"
              :key="agenda.id"
            >
              <CardAgenda
                :title="agenda.title"
                :slug="agenda.slug"
                :location="agenda.location"
                :shortText="agenda.shortText"
                :published="agenda.published"
              />
            </div>
          </div>
        </div>

        <div class="col-md-5 col-lg-4">
          <h2 class="header"><i class="bi-newspaper"></i> Berita lainnya</h2>
          <div class="row mt-4">
            <div class="col-12 mb-3" v-for="news in news" :key="news.id">
              <CardBerita
                :title="news.title"
                :thumbnail="news.thumbnail"
                :published="news.published"
                :slug="news.slug"
              ></CardBerita>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer :tentang="layout.tentang_sekolah" />
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Agenda",
  components: {
    Navbar: () => import("@/components/Navbar.vue"),
    Welcome: () => import("@/components/Welcome.vue"),
    CardAgenda: () => import("@/components/CardAgenda.vue"),
    CardBerita: () => import("@/components/CardBerita.vue"),
    Footer: () => import("@/components/Footer.vue"),
  },

  data() {
    return {
      agenda: [],
      news: [],
      layout: [],
    };
  },

  mounted() {
    this.getLayout();
    this.getAgenda();
    this.getNews();
  },

  methods: {
    async getAgenda() {
      let response = await axios.get("agenda");
      this.agenda = response.data.data;
    },
    async getNews() {
      let response = await axios.get("news/4");
      this.news = response.data.data;
    },
    async getLayout() {
      let response = await axios.get("layout");
      this.layout = response.data.data;
    },
  },
};
</script>
